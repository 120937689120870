import icons from './plugins/icons'
import store from './store'
export default [
    {
        icon: "mdi-desktop-mac-dashboard",
        title: "Dashboard",
        gate: { subject: 'dashboard', action: 'show' },
        to: "/"
    },
    {
        header: store.getters.translate("administration"),
        gate: { action: ['exam', /*'planner', 'examstatuses',*/ 'candidate', 'certificate', 'dossier', 'assessor', 'note'] }
    },
    {
        icon: icons['exams'],
        gate: { subject: 'exam', action: 'show' },
        title: store.getters.translate("exams"),
        to: "/exams"
    },
    /*{
        icon: icons['planner'],
        gate: { subject: 'planning', action: 'show' },
        title: store.getters.translate("planner"),
        to: "/planning",
    },*/
    /*{
        icon: icons['examstatuses'],
        gate: { subject: 'examstatuses', action: 'show' },
        title: store.getters.translate("exam_statuses"),
        to: "/examstatuses",
    },*/
    {
        icon: icons['candidates'],
        gate: { subject: 'candidate', action: 'show' },
        title: store.getters.translate("candidates"),
        to: "/candidates",
    },
    {
        icon: icons['certificates'],
        gate: { subject: 'certificate', action: 'show' },
        title: store.getters.translate("certificates"),
        to: "/certificates",
    },
    {
        icon: icons['dossiers'],
        gate: { subject: 'dossier', action: 'show' },
        title: store.getters.translate("dossiers"),
        to: "/dossiers",
    },
    {
        icon: icons['assessors'],
        gate: { subject: 'assessor', action: 'show' },
        title: store.getters.translate("assessors"),
        to: "/assessors",
    },
    {
        icon: icons['notes'],
        gate: { subject: 'note', action: 'show' },
        title: store.getters.translate("notes"),
        to: "/notes",
    },
    {
        header: store.getters.translate("relations_management"),
        gate: { action: ['company', 'contact'] }
    },
    {
        icon: icons['companies'],
        gate: { subject: 'company', action: 'show' },
        title: store.getters.translate("companies"),
        to: "/companies",
    },
    {
        icon: icons['contacts'],
        gate: { subject: 'contact', action: 'show' },
        title: store.getters.translate("contacts"),
        to: "/contacts",
    },
    {
        header: store.getters.translate("configuration"),
        gate: { action: ['scheme', 'template', 'location', 'room'] }
    },
    {
        icon: icons['schemes'],
        gate: { subject: 'scheme', action: 'show' },
        title: store.getters.translate("schemes"),
        to: "/schemes",
    },
    {
        icon: icons['templates'],
        gate: { subject: 'template', action: 'show' },
        title: store.getters.translate("templates"),
        to: "/templates",
    },
    {
        icon: icons['locations'],
        gate: { subject: 'location', action: 'show' },
        title: store.getters.translate("locations"),
        to: "/locations",
    },
    {
        icon: icons['rooms'],
        gate: { subject: 'room', action: 'show' },
        title: store.getters.translate("rooms"),
        to: "/rooms",
    },
    {
        header: store.getters.translate("settings"),
        gate: { action: ['translation', 'field', 'form', 'tag', 'employee', 'role', 'workflow', 'setting'] }
    },
    {
        icon: icons['translations'],
        gate: { subject: 'translation', action: 'show' },
        title: store.getters.translate("translations"),
        to: "/translations",
    },
    {
        icon: icons['fields'],
        gate: { subject: 'field', action: 'show' },
        title: store.getters.translate("fields"),
        to: "/fields",
    },
    {
        icon: icons['forms'],
        gate: { subject: 'form', action: 'show' },
        title: store.getters.translate("forms"),
        to: "/forms",
    },
    {
        icon: icons['tags'],
        gate: { subject: 'tag', action: 'show' },
        title: store.getters.translate("tags"),
        to: "/tags",
    },
    {
        icon: icons['employees'],
        gate: { subject: 'employee', action: 'show' },
        title: store.getters.translate("employees"),
        to: "/employees",
    },
    {
        icon: icons['roles'],
        gate: { subject: 'role', action: 'show' },
        title: store.getters.translate("roles"),
        to: "/roles",
    },
    {
        icon: icons['workflows'],
        gate: { subject: 'workflow', action: 'show' },
        title: store.getters.translate("workflows"),
        to: "/workflows",
    },
    {
        icon: icons['settings'],
        gate: { subject: 'settings', action: 'show' },
        title: store.getters.translate("settings"),
        to: "/settings",
    }
]